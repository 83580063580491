<template>
  <v-container fluid>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="editEmployeeProfileConfirm" />
    <base-snackbar-notification ref="editEmployeeProfileSnackbar" />
<!--    <v-row>-->
<!--      <v-btn elevation="0" dense color="warning" @click="createNotification">Create Notification</v-btn>-->
<!--    </v-row>-->
    <v-row v-if="notificationsInitialized">
      <v-col cols="12" md="6" :v-for="notifications.records.length > 0" width="100vw" class="px-0 py-0">
        <v-list
            two-line
            v-touch="{
              down: () => this.refreshNotifications = true
            }"
        >
          <v-list-item-group
              @change="notificationListChange"
          >
            <template v-for="(item, index) in notifications.records">
              <v-list-item :key="item.description" :value="item">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title
                        class="text-body-1 "
                        v-bind:class="{ 'font-weight-bold': item.read === false, 'grey--text': item.read }"
                        v-text="item.title"
                    ></v-list-item-title>
                    <v-list-item-subtitle class="grey--text" v-text="item.body"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      <p
                          v-text="formatDate(item.createdAt)"
                          class="mb-0"
                      ></p>
                      <v-icon
                          class="d-flex justify-end"
                          v-bind:class="{ 'grey--text': item.read, 'secondary--text': item.read === false }"
                          v-text="item.actions ? 'mdi-chevron-right-circle-outline' : 'mdi-chevron-right'"
                      ></v-icon>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider
                  v-if="index < notifications.count - 1"
                  :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-avatar class="" size="200" tile>
          <v-img src="../../assets/FairwageLogo.png"></v-img>
        </v-avatar>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12"><p>There are no notifications for you just yet.</p><p>Check back soon.</p></v-col>
      <v-col
        cols="12"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-avatar class="" size="200" tile>
          <v-img src="../../assets/FairwageLogo.png"></v-img>
        </v-avatar> </v-col
    ></v-row>
    <v-dialog
        v-model="showNotificationDialog"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn
              icon
              dark
              @click="showNotificationDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">Notification Details</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="deleteNotification(currentNotification)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn
              icon
              dark
              :loading="unreadLoading"
              :disabled="unreadLoading"
              @click="markNotificationUnread(currentNotification)"
          >
            <v-icon>{{ this.unreadIcon }}</v-icon>
          </v-btn>
        </v-toolbar>
        <template vif="currentNotification !== null">
          <v-card-text class="px-2">
            <v-container>
              <v-row>
                <v-col cols="12 pb-0 d-flex justify-end" >
                  <span
                      v-text="$moment(currentNotification.createdAt).format('HH:mm A - DD MMM YYYY')"
                      class="text-content"
                  ></span>
                </v-col>
                <v-col cols="12 pb-0">
                  <span
                      v-text="currentNotification.title"
                      class="text-h4 font-weight-bold black--text"
                  ></span>
                </v-col>
                <v-col cols="12">
                  <span
                      v-text="currentNotification.body"
                      class="text-body-1"
                  ></span>
                </v-col>
                <template v-if="currentNotificationHasActions" >
                  <template v-if="currentNotification.actions.completed" >
                    <v-col cols="12">
                  <span class="text-body-1 secondary--text">This action has been completed.</span>
                    </v-col>
                  </template>
                  <v-row class="mt-4 ml-0">
                    <v-col cols="12">
                      <template v-for="(item, index) in currentNotification.actions.actionButtons">
                          <v-btn
                              :key="'actionbutton-' + index"
                              elevation="0"
                              class="mr-4"
                              v-text="item"
                              v-bind:class="{ 'secondary': item === 'Approve', 'primary': item === 'Reject' }"
                              :disabled="currentNotification.actions.completed"
                              :loading="notificationActionLoading"
                              @click="notificationActionClick(currentNotification, index)"
                          >
                          </v-btn>
                      </template>
                    </v-col>
                  </v-row>
                </template>
              </v-row>
            </v-container>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost"
import moment from "moment"
export default {
  name: "Notifications",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
    setInterval(() => {
      this.refreshNotifications = true
    }, 10000)
  },
  data() {
    return {
      isLoading: false,
      firstLoadDone: false,
      showNotificationDialog: false,
      notifications: [],
      currentNotification: { },
      currentNotificationHasActions: false,
      refreshNotifications: false,
      notificationsCount: 0,
      unreadLoading: false,
      unreadNotificationStatus: 'open',
      notificationActionLoading: false
    }
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,

    notifications: {
      query: gql`
        query Notifications($limit: Int!, $skip: Int!, $query: JSON!) {
          notifications(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              title
              body
              done
              assignedTo
              assignedToName
              read
              createdBy
              createdByName
              createdAt
              updatedAt
              actions {
                type
                model
                modelId
                actionButtons
                completed
                completedAt
              }
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const limit = 0;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: { assignedTo: this.me.id },
        };
      },
      watchLoading(isLoading, countModifier) {
        if(!this.firstLoadDone) {
          this.isLoading = isLoading;
        }
      },
      result(response, key) {
        if (response.data) {
          this.notifications = response.data.notifications;
          this.notificationsCount = response.data.notifications.count;
          this.firstLoadDone = true
        }
      },
    },
  },
  methods: {
    async createNotification() {
      const self = this;

      const response = await self.$apollo.mutate({
        mutation: gql`
          mutation createNotification($notification: NotificationCreateInput!) {
            createNotification(notification: $notification) {
              id
            }
          }
        `,
        variables: {
          notification: {
            title: 'Mamiki has logged work.',
            body: 'For the 16th May.',
            createdBy: this.me.id,
            assignedTo: this.me.id,
            read: false
          },
        },
      }).catch((e) => { return e})
      if (response instanceof Error) {
        // Error handled by the ContractForm Component
        console.error('Error creating the Notification: ', response.message)
        return response.message
      }
      this.refreshNotifications = true
      console.log('Notification Created: ', response)
    },
    notificationListChange(item) {
      if (!this.$_.isUndefined(item)) {
        this.currentNotification = item
      }
      this.currentNotificationHasActions = false
      if (this.$_.has(this.currentNotification, 'actions.actionButtons')) {
        this.currentNotificationHasActions = true
      }
      this.unreadNotificationStatus = 'open'
      this.showNotificationDialog = true
      this.markNotificationRead(item)
    },
    async markNotificationUnread(item) {
      this.unreadLoading = true
      this.unreadNotificationStatus = 'open'
      const notification = {
        id: item.id,
        read: false,
        updatedBy: this.me.id,
      }
      const response = await this.updateNotification(notification).catch((e) => { return e})
      if (response instanceof Error) {
        console.error('Error updating the Notification: ', response.message)
        return response.message
      }
      this.unreadLoading = false
      this.unreadNotificationStatus = 'close'
      this.refreshNotifications = true
      return true
    },
    async markNotificationRead(item) {
      const notification = {
        id: item.id,
        read: true,
        updatedBy: this.me.id,
      }
      const response = await this.updateNotification(notification).catch((e) => { return e})
      if (response instanceof Error) {
        console.error('Error updating the Notification: ', response.message)
        return response.message
      }
      this.refreshNotifications = true
      return true
    },
    async updateNotification(notification) {
      const self = this;

      const response = await self.$apollo.mutate({
        mutation: gql`
          mutation updateNotification($notification: NotificationUpdateInput!) {
            updateNotification(notification: $notification) {
              id
              updated
            }
          }
        `,
        variables: {
          notification: notification,
        },
      }).catch((e) => { return e})
      if (response instanceof Error) {
        // Error handled by the ContractForm Component
        console.error('Error updating the Notification: ', response.message)
        return response.message
      }
    },
    async deleteNotification(notification) {
      const self = this;
      const dialogAnswer = await this.$refs.editEmployeeProfileConfirm.open("Warning", "Are you sure you want to delete this Notification?", "warning", "yesno")
      if (dialogAnswer) {
      const response = await self.$apollo.mutate({
        mutation: gql`
          mutation deleteNotification($id: ID!) {
            deleteNotification(id: $id) {
              id
              deleted
            }
          }
        `,
        variables: {
          id: notification.id,
        },
        }).catch((e) => { return e})
        if (response instanceof Error) {
          console.error('Error deleting the Notification: ', response.message)
          this.$refs.editEmployeeProfileConfirm.open("Error", "There was a problem while deleting the notification. Please try again or contact support.", "error")
          return response.message
        } else {
          this.showNotificationDialog = false
          this.refreshNotifications = true
        }
      }
    },
    async notificationActionClick(notification, actionButtonIndex) {
      this.notificationActionLoading = true
      const notificationId = this.$_.get(notification, 'id', null)
      const actions = this.$_.get(notification, 'actions', null)
      delete actions.__ob__
      delete actions.__typename
      const response = await this.$apollo.mutate({
        mutation: gql`
          mutation runNotificationAction($id: ID!, $actions: NotificationActionInput!, $actionButtonIndex: Int!) {
            runNotificationAction(id: $id, actions: $actions, actionButtonIndex: $actionButtonIndex) {
              id
              run
              updated
              notification {
                id
                title
                body
                done
                assignedTo
                assignedToName
                read
                createdBy
                createdByName
                createdAt
                updatedAt
                actions {
                  type
                  model
                  modelId
                  actionButtons
                  completed
                  completedAt
                }
              }
            }
          }
        `,
        variables: {
          id: notificationId,
          actions: actions,
          actionButtonIndex: actionButtonIndex,
        },
      }).catch((e) => { return e})
      if (response instanceof Error) {
        console.error('Error running the Notification actions: ', response.message)
        this.$refs.editEmployeeProfileConfirm.open("Error", "There was a problem while running the action. Please try again or contact support.", "error")
        this.notificationActionLoading = false
        return response.message
      } else {
        this.notificationActionLoading = false
        this.currentNotification = response.data.runNotificationAction.notification
        this.refreshNotifications = true
      }
    },
    formatDate(date) {
      if(!this.$moment(date).isBefore(moment(), "day")) {
        return this.$moment(date).format('HH:mm')
      } else {
        const dateR = this.$moment(date).format('DD MMM')
        return this.$moment(date).format('DD MMM')
      }
    },
  },
  computed: {
    notificationsInitialized: function () {
      return (!this.$_.isUndefined(this.notifications) && this.$_.size(this.notifications.records) > 0)
    },
    notificationsFinalCount: function () {
      if (!this.$_.isUndefined(this.notifications)) {
        // Find total unread notifications
        const count = this.$_.size(this.$_.filter(this.notifications.records, { read: false }))
        return count
      }
      return 0
    },
    unreadIcon: function () {
      let icon = 'mdi-email-open-outline'
      if (this.unreadNotificationStatus === 'close') return 'mdi-email-outline'
      if (this.unreadNotificationStatus === 'open') return 'mdi-email-open-outline'
      return icon
    },
    deletedIcon: function () {
      return this.$_.isUndefined(this.currentNotification) || this.currentNotification.deleted ? 'mdi-delete' : 'mdi-delete-outline'
    },
  },
  watch: {
    refreshNotifications: async function (state) {
      if (state) {
        await this.$apollo.queries.notifications.refetch()
        this.refreshNotifications = false
        this.$emit('refreshNotifications', this.notificationsFinalCount)
      }
    },
  }
};
</script>

<style scoped></style>
